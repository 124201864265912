import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw } from 'vue-router'
import { useNProgress } from '@vueuse/integrations/useNProgress'
import '@/assets/styles/nprogress.scss'

// 路由相关数据
import { asyncRoutes, asyncRoutesByFilesystem, constantRoutes, constantRoutesByFilesystem } from './routes'
import pinia from '@/store'
import useSettingsStore from '@/store/modules/settings'
import useKeepAliveStore from '@/store/modules/keepAlive'
import useUserStore from '@/store/modules/user'
import useMenuStore from '@/store/modules/menu'
import useRouteStore from '@/store/modules/route'
import useCommonsStore from '@/store/modules/commons'
// auth tools 20221024 comment
import {
  getBusinessType,
  // getLsMenuList,
  // getMenuSign,
  // getNickName,
  getNickNameLS,
  getToken,
  setAPIKey,
  setBusinessType,
  // setLsMenuList,
  // setMenuSign,
  setNickName,
  setNickNameLS,
  setProductID,
  // setToken,
  // removeToken,
  // getProductID,
  // removeProductID,
  // getLecoomisSessionKey
  // setPopOwnerCode
} from '@/utils/auth' // get token from cookie

// crypt util
import { aesUtil } from '@/utils/encryptn'

// 添加、移除水印工具watermark
import {
  removeWaterMark,
  setWaterMark,
} from '@/utils/waterMark'
import {
  /* 平台 */
  getGlobalConfig,
  // getMenuList,
  /* 获取MVP全局配置接口 */
  // getMvpGlobalConfig,
} from '@/api/modules/user'

const { isLoading } = useNProgress()

const router = createRouter({
  history: createWebHistory((process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'production') ? 'admin/nx-admin' : '/'),
  routes: useSettingsStore(pinia).settings.app.routeBaseOn === 'filesystem' ? constantRoutesByFilesystem : constantRoutes as RouteRecordRaw[]
})

// 路由白名单
const whiteList: string[] = [
  // '/',
  '/login',
  // 记登录状态授权重定向
  '/auth-redirect',
  // 重置密码
  '/account-config/reset-pwd',
  // 账号管理>强制修改密码
  // '/account-config/force-change-pwd'
  // SSO
  '/account-config/sso-login'
]

router.beforeEach(async (to, from, next) => {
  // console.log('router before ...', router)
  const settingsStore = useSettingsStore()
  const commonsStore = useCommonsStore()
  const userStore = useUserStore()
  const menuStore = useMenuStore()
  const routeStore = useRouteStore()
  settingsStore.settings.app.enableProgress && (isLoading.value = true)
  /* 平台用户的返回老MIS路由对象 */
  /* const returnURLObj = {
    id: '14',
    parentId: '0',
    enabled: '1',
    menu_type: 1,
    component: 'layout',
    path: `//${window.location.hostname}/admin/`,
    meta: { title: '返回原MIS', icon: 'icon-link', roles: ['admin'] },
  } */
  // 20221024 comment
  const nickName = getNickNameLS() || ''
  // console.log('permission nickname', nickName)
  /* businessType为1，是前端区分平台和商户的类型，由原MIS跳转过来由于没有走前端登录流程（写token和businessType）需要设置默认值 */
  let businessType = getBusinessType()
  if (businessType) {
    businessType = aesUtil.deCryptPlainText(businessType)
  } else {
    businessType = '1'
    setBusinessType(aesUtil.encryptPlainText(businessType))
  }
  const hasToken = getToken()
  console.log('permission router.beforeEach hasToken', hasToken)
  // 20220801
  // const hasToken = "admin-token"
  // console.log('businessType', businessType)
  // 注意这个tokenLimit须恢复
  // console.log('tokenLimit', userStore.tokenLimit)
  /* 此处判断cookie里有admin-Token就不走这个流程了 */
  // 是否已登录
  // console.log('userStore.isLogin', userStore.isLogin)
  // if (userStore.isLogin) {
  if (hasToken) {
    console.log('routeStore.isGenerate', routeStore.isGenerate)
    /* 是否已根据权限动态生成并注册路由 */
    if (routeStore.isGenerate) {
      // 导航栏如果不是 single 模式，则需要根据 path 定位主导航的选中状态
      settingsStore.settings.menu.menuMode !== 'single' && menuStore.setActived(to.path)
      /* 商户角色 20221125 */
      // console.log('business type', businessType)
      const enabledTUT = localStorage.getItem('enabled_tut')
      if (enabledTUT === '1') {
        console.log('session time now: ', Date.now(), 'globalTokenUpdateTime', commonsStore.globalTokenUpdateTime, 'globalTokenDeviderTime', commonsStore.globalTokenDeviderTime, 'reduce', Date.now() - commonsStore.globalTokenUpdateTime)
      }
      console.log('Date.now() - commonsStore.globalTokenUpdateTime > commonsStore.globalTokenDeviderTime', Date.now() - commonsStore.globalTokenUpdateTime > commonsStore.globalTokenDeviderTime)
      if (from.name === 'login' || Date.now() - commonsStore.globalTokenUpdateTime > commonsStore.globalTokenDeviderTime) {
        /* if (businessType === '2') {
        } else  */
        if (businessType.toString() === '1' && userStore.tokenLimit === 0 || !nickName) {
          /* 平台角色：1.token失效时触发退出条件。2.获取昵称等信息 */
          await getGlobalConfig().then(async function(res) {
            // console.log('get global config', res)
            if (res.code.toString() === '200') {
              userStore.setTokenLimit(0)
              setNickName(res.data.username)
              setNickNameLS(res.data.username)
              setProductID(aesUtil.encryptPlainText(res.data.product_id))
              /* 设置avatar */
              if (Object.prototype.hasOwnProperty.call(res.data, 'avatar') && res.data.avatar.indexOf('http') >= 0) {
                // store.dispatch('user/setAvatar', res.data.avatar)
                userStore.setAvatar(res.data.avatar)
              }
              if (Object.prototype.hasOwnProperty.call(res.data, 'openapi_key')) {
                setAPIKey(res.data.openapi_key)
              }
              /* 平台类型用户 */
              userStore.setGlobalBusinessType(1)
              userStore.setLoginLoading(false)
              // next(true)
            }/*  else {
              next(false)
            } */
          }).catch((error) => {
            console.log('get global config error', error)
            // Message.error(error.msg || 'Has Error')
            // setBusinessType(aesUtil.encryptPlainText('2'))
            // location.reload()
            next(false)
          })
        }
      }
      /* ； */
      commonsStore.setGlobalTokenUpdateTime(Date.now())
      // 如果已登录状态下，进入登录页会强制跳转到主页
      if (to.name === 'login') {
        console.log('current path is login', to)
        next({
          name: 'DataMiddleOffice',
          replace: true,
        })
      } else if (!settingsStore.settings.home.enable && to.name === 'home') {
        // 如果未开启主页，但进入的是主页，则会进入侧边栏导航第一个模块
        /* 路由守卫添加页面水印 */
        setWaterMark(nickName)
        if (menuStore.sidebarMenus.length > 0) {
          next({
            path: menuStore.sidebarMenusFirstDeepestPath,
            replace: true,
          })
        } else {
          // 如果侧边栏导航第一个模块无法命中，则还是进入主页
          next()
        }
      } else {
        // 正常访问页面
        next()
        /* 路由守卫添加页面水印 */
        setWaterMark(nickName)
      }
    } else {
      // 生成动态路由
      console.log('settingsStore.settings.app.routeBaseOn >', settingsStore.settings.app.routeBaseOn)
      switch (settingsStore.settings.app.routeBaseOn) {
        case 'frontend':
          await routeStore.generateRoutesAtFront(asyncRoutes)
          break
        case 'backend':
          await routeStore.generateRoutesAtBack()
          break
        case 'filesystem':
          await routeStore.generateRoutesAtFilesystem(asyncRoutesByFilesystem)
          // 文件系统生成的路由，需要手动生成导航数据
          switch (settingsStore.settings.menu.baseOn) {
            case 'frontend':
              await menuStore.generateMenusAtFront()
              break
            case 'backend':
              await menuStore.generateMenusAtBack()
              break
          }
          break
      }
      // 注册并记录路由数据
      // 记录的数据会在登出时会使用到，不使用 router.removeRoute 是考虑配置的路由可能不一定有设置 name ，则通过调用 router.addRoute() 返回的回调进行删除
      const removeRoutes: (() => void)[] = []
      routeStore.flatRoutes.forEach((route) => {
        if (!/^(https?:|mailto:|tel:)/.test(route.path)) {
          removeRoutes.push(router.addRoute(route as RouteRecordRaw))
        }
      })
      if (settingsStore.settings.app.routeBaseOn !== 'filesystem') {
        routeStore.flatSystemRoutes.forEach((route) => {
          removeRoutes.push(router.addRoute(route as RouteRecordRaw))
        })
      }
      // console.log('removeRoutes', removeRoutes)
      routeStore.setCurrentRemoveRoutes(removeRoutes)
      // 动态路由生成并注册后，重新进入当前路由
      // console.log('before next to', to)
      next({
        path: to.path,
        query: to.query,
        replace: true,
      })
    }
  } else {
    /* 未登录跳转白名单页——登录页去除页面水印 */
    console.log('none token to login')
    removeWaterMark()
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly 在免登录白名单，直接进入
      next()
    } else {
      if (to.name !== 'login') {
        next({
          name: 'login',
          query: {
            redirect: to.fullPath !== '/' ? to.fullPath : undefined,
          },
        })
      } else {
        next()
      }
    }
  }
})

router.afterEach((to, from) => {
  // console.log('permission router after each.')
  const settingsStore = useSettingsStore()
  const keepAliveStore = useKeepAliveStore()
  settingsStore.settings.app.enableProgress && (isLoading.value = false)
  // 设置页面 title
  if (settingsStore.settings.app.routeBaseOn !== 'filesystem') {
    settingsStore.setTitle(to.meta.breadcrumbNeste?.at(-1)?.title ?? to.meta.title)
  } else {
    settingsStore.setTitle(to.meta.title)
  }
  // 判断当前页面是否开启缓存，如果开启，则将当前页面的 name 信息存入 keep-alive 全局状态
  if (to.meta.cache) {
    const componentName = to.matched.at(-1)?.components?.default.name
    if (componentName) {
      keepAliveStore.add(componentName)
    } else {
      console.warn('该页面组件未设置组件名，会导致缓存失效，请检查')
    }
  }
  // 判断离开页面是否开启缓存，如果开启，则根据缓存规则判断是否需要清空 keep-alive 全局状态里离开页面的 name 信息
  if (from.meta.cache) {
    const componentName = from.matched.at(-1)?.components?.default.name
    if (componentName) {
    // 通过 meta.cache 判断针对哪些页面进行缓存
      switch (typeof from.meta.cache) {
        case 'string':
          if (from.meta.cache !== to.name) {
            keepAliveStore.remove(componentName)
          }
          break
        case 'object':
          if (!from.meta.cache.includes(to.name as string)) {
            keepAliveStore.remove(componentName)
          }
          break
      }
      // 如果进入的是 reload 页面，则也将离开页面的缓存清空
      if (to.name === 'reload') {
        keepAliveStore.remove(componentName)
      }
    }
  }
  document.documentElement.scrollTop = 0
})

export default router
